







































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";

export default Vue.extend({
  data() {
    return {
      drawer: null as any,
      title: "Giriş",
      course: {
        id: "",
        price: 0,
        name: "",
        color: "",
        code: "",
        previewImg: "",
        urlCode: "",
        learningOutcomes: [],
        modules: [],
        desc: {
          short: "",
          long: ""
        },
        rating: {
          value: 0,
          count: 0
        }
      },
      progress: 15,
      currentMenuItem: {} as any
    };
  },
  computed: {
    menuItems(): any[] {
      return [
        {
          icon: "home",
          title: "Giriş",
          link: { name: "supervisor-course-home" },
          exact: true
        },
        {
          icon: "play_circle_filled",
          title: "Konular",
          link: { name: "supervisor-course-lessons" },
          exact: false
        },
        {
          icon: "chat",
          title: "Tartışmalar",
          link: { name: "supervisor-course-discussions" },
          exact: false
        }
      ];
    }
  },

  methods: {
    goTo(item: any) {
      this.currentMenuItem = item;
      this.title = item.title;
    },
    signOut() {
      this.$store.dispatch("auth/signOut");
    }
  },
  mounted() {
    this.currentMenuItem = this.menuItems[0];
    this.title = this.currentMenuItem.title;

    this.$store.dispatch("auth/changeTheme", "businessGroup");

    // Ders bilgilerini al
    firebase
      .firestore()
      .collection("courses")
      .where("urlCode", "==", this.$route.params.courseUrlCode)
      .limit(1)
      .get()
      .then(qs => {
        const course = qs.docs[0];
        if (course.exists) {
          this.course.id = course.id;
          this.course.price = course.data().price;
          this.course.name = course.data().name;
          this.course.color = course.data().color;
          this.course.code = course.data().code;
          this.course.previewImg = course.data().previewImg;
          this.course.urlCode = course.data().urlCode;
          this.course.learningOutcomes = course.data().learningOutcomes;
          this.course.modules = course.data().modules;
          this.course.desc = course.data().desc;
          this.course.rating = course.data().rating;
        }
      })
      .catch(error => {
        throw `Eğitim bilgileri alınamadı: ${error}`;
      });
  }
});
